import { makeStyles, Typography, Table, TableHead, TableBody, TableRow, TableCell, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableHead: {
    background: '#F4F4F6',
  },
  table: {
    '& td': {
      padding: '0.9em',
    },
  },
});

export function SupportedList() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <strong>{t('UnBrowserSupport.SupportList.Title')}</strong>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://www.google.com/chrome/"
                >
                  {t('Chrome')}
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://www.mozilla.org/en-US/firefox/new/"
                >
                  {t('Firefox')}
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://support.apple.com/downloads/safari"
                >
                  {t('Safari')}
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              <Typography variant="body1">
                <Link
                  color="inherit"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href="https://www.microsoft.com/en-us/edge"
                >
                  {t('Edge')}
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
