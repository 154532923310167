import React from 'react';
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography, Paper, makeStyles, Theme, createStyles } from '@material-ui/core';
import ErrorIllustration from './ErrorIllustration.png';
import { ErrorIcon } from '../../../../icons/ErrorIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'block',
    },
    heading: {
      position: 'relative',
      marginTop: '20px',
    },
    grantPermissions: {
      fontSize: '16px',
      paddingBottom: '16px',
    },
    /* The size of the image is explicitly stated here so that this content can properly be centered vertically
  before the image is loaded.*/
    illustration: {
      maxHeight: '174px',
      maxWidth: '337px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    refreshButton: {
      clear: 'left',
      [theme.breakpoints.down('md')]: {
        clear: 'initial',
        marginBottom: '2em',
      },
    },
    paperContainer: {
      float: 'right',
      marginRight: '1em',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 0 2.5em 0',
      },
    },
    paper: {
      display: 'inline-block',
      padding: '20px',
      borderRadius: '8px',
    },
  })
);

export function getDeviceErrorPaneContent(t: any, error?: Error) {
  let headline = '';
  let message = '';

  switch (true) {
    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = t('permissions.Error.NotAllowed');

      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message = t('permissions.Error.NotAllowed.Blocked');
      } else {
        message = t('permissions.Error.NotAllowed.Access');
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = t('permissions.Error.DeviceNotFound');
      message = t('permissions.Error.DeviceNotFound.Desc');
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = t('permissions.Error.Media');
      message = `${error!.name} ${error!.message}`;
      break;
  }
  return {
    headline,
    message,
  };
}

export function PermissionError() {
  const { t } = useTranslation();
  const { state } = useAppStateContext();
  const { headline, message } = getDeviceErrorPaneContent(t, state.deviceError!);
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.mainContainer}>
        <Grid item lg={5} className={classes.header}>
          <Typography variant="h1" gutterBottom className={classes.heading}>
            <ErrorIcon />
            {headline}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
        </Grid>

        <Grid item lg={5} className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1" className={classes.grantPermissions}>
              <strong>{t('Permissions')}</strong>
            </Typography>
            <img src={ErrorIllustration} alt="Settings Illustration" className={classes.illustration} />
          </Paper>
        </Grid>

        <Grid item lg={5} className={classes.refreshButton}>
          <Typography variant="body1" gutterBottom>
            <strong>{t('permissions.Error.Desc')}</strong>
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            style={{ color: 'white' }}
          >
            {t('Button.RefreshPage')}
          </Button>
        </Grid>
      </div>
    </Container>
  );
}
