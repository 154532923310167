export function DownloadIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.625 3.5C10.625 3.15482 10.3452 2.875 10 2.875C9.65482 2.875 9.375 3.15482 9.375 3.5V11.9911L7.34194 9.95808C7.09787 9.714 6.70214 9.714 6.45806 9.95808C6.21398 10.2022 6.21398 10.5979 6.45806 10.842L9.58306 13.967C9.82714 14.211 10.2229 14.211 10.4669 13.967L13.5919 10.842C13.836 10.5979 13.836 10.2022 13.5919 9.95808C13.3479 9.714 12.9521 9.714 12.7081 9.95808L10.625 12.0411V3.5ZM2.875 16.5C2.875 16.1548 3.15482 15.875 3.5 15.875H16.5C16.8452 15.875 17.125 16.1548 17.125 16.5C17.125 16.8452 16.8452 17.125 16.5 17.125H3.5C3.15482 17.125 2.875 16.8452 2.875 16.5Z"
        fill="white"
      />
    </svg>
  );
}
