import {
  createStyles,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { ErrorStatus, SuccessStatus, WarningStatus } from '../../../../icons/StatusIcons';
import { TwilioStatus, TwilioAPIStatus } from '../../../AppStateProvider/AppStateProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      width: '100%',
      maxWidth: '600px',
    },
    content: {
      padding: '3em',
      [theme.breakpoints.down('sm')]: {
        padding: '2em',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '0.3em',
      },
    },
  })
);

const serviceStatusObj = {
  operational: { status: 'Up', icon: <SuccessStatus />, translate: 'Connection.Up' },
  major_outage: { status: 'Major Outage', icon: <ErrorStatus />, translate: 'Connection.MajorOutage' },
  partial_outage: { status: 'Partial Outage', icon: <WarningStatus />, translate: 'Connection.PartialOutage' },
  degraded_performance: { status: 'Degraded', icon: <WarningStatus />, translate: 'Connection.Degraded' },
};

export function TwilioStatusRow({ t, status, serviceName }: { t: any; status?: TwilioAPIStatus; serviceName: string }) {
  const classes = useStyles();
  const serviceStatus = status ? serviceStatusObj[status] : null;

  return (
    <TableRow>
      <TableCell>
        <div className={classes.iconContainer}>
          {serviceStatus?.icon}
          <Typography variant="body1">
            <strong>{t(serviceStatus?.translate)}</strong>
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{serviceName}</Typography>
      </TableCell>
    </TableRow>
  );
}

interface ConnectionModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  serviceStatuses: TwilioStatus | null;
  signalingGateway: string;
  turnServers: string;
}

export function ConnectionModal({
  isModalOpen,
  setIsModalOpen,
  serviceStatuses,
  signalingGateway,
  turnServers,
}: ConnectionModalProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} classes={{ paper: classes.modal }}>
        <DialogTitle disableTypography>
          <Typography variant="h3">{t('Connection.DialogTitle')}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setIsModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">
                    <strong>{t('Connection.Status')}</strong>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    <strong>{t('Connection.Type')}</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TwilioStatusRow
                t={t}
                status={serviceStatuses?.['Compositions']}
                serviceName={t('Connection.Compositions')}
              />
              <TwilioStatusRow t={t} status={serviceStatuses?.['Go Rooms']} serviceName={t('Connection.GoRooms')} />
              <TwilioStatusRow
                t={t}
                status={serviceStatuses?.['Group Rooms']}
                serviceName={t('Connection.GroupRooms')}
              />
              <TwilioStatusRow
                t={t}
                status={serviceStatuses?.['Network Traversal Service']}
                serviceName={t('Connection.NetworkTraversal')}
              />
              <TwilioStatusRow
                t={t}
                status={serviceStatuses?.['Peer-to-Peer Rooms']}
                serviceName={t('Connection.PeerToPeerRooms')}
              />
              <TwilioStatusRow
                t={t}
                status={serviceStatuses?.['Recordings']}
                serviceName={t('Connection.Recordings')}
              />
              <TableRow>
                <TableCell>
                  <div className={classes.iconContainer}>
                    {signalingGateway === 'Reachable' ? <SuccessStatus /> : <ErrorStatus />}
                    <Typography variant="body1">
                      <strong>{t(`Connection.${signalingGateway}`)}</strong>
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{t('Connection.SignalingGateway')}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className={classes.iconContainer}>
                    {turnServers === 'Reachable' ? <SuccessStatus /> : <ErrorStatus />}
                    <Typography variant="body1">
                      <strong>{t(`Connection.${turnServers}`)}</strong>
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{t('Connection.Reachable.TurnServers')}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
