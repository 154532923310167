import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

const currentLang = window?.location?.pathname === '/' ? 'en' : window?.location?.pathname.replaceAll('/', '');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: currentLang,
    debug: process.env === 'development',
    lng: currentLang,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

console.log(i18n.t('HowAreYou'));

export default i18n;
