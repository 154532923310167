import { makeStyles, Button, Container, Grid, Typography, Theme, createStyles } from '@material-ui/core';
import { ActivePane, useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { CheckMark } from '../../../icons/CheckMark';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { getQualityScore } from '../Quality/getQualityScore/getQualityScore';
import { QualityScore } from '../Quality/Quality';
import { SmallError } from '../../../icons/SmallError';
import SomeFailed from './SomeFailed.png';
import TestsPassed from './TestsPassed.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      float: 'left',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
        paddingBottom: '1em',
      },
    },
    buttonContainer: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: '1em',
      width: '100%',
    },
    resultsList: {
      float: 'right',
      [theme.breakpoints.down('md')]: {
        float: 'initial',
      },
    },
    resultContainer: {
      marginTop: '1.5em',
      '&:not(:last-child)': {
        paddingBottom: '1.5em',
        borderBottom: '0.1em solid #8891AA',
      },
      [theme.breakpoints.down('md')]: {
        '&:last-child': {
          paddingBottom: '1.5em',
        },
      },
    },
    iconContainer: {
      display: 'flex',
      '& svg': {
        margin: '0.2em 0.8em 0 0',
      },
    },
    downloadButton: {
      '& svg': {
        position: 'relative',
        left: '-5px',
      },
    },
    restartButton: {
      backgroundColor: '#FFFFFF',
      borderColor: '#8891AA',
    },
    illustration: {
      marginTop: '7em',
    },
    hardwareButton: {
      marginRight: '1.5em',
    },
    gutterBottom: {
      marginBottom: '1em',
      [theme.breakpoints.down('md')]: {
        marginBottom: '1.5em',
      },
    },
  })
);

export function Results() {
  const { t } = useTranslation();
  const { state, downloadFinalTestResults, dispatch } = useAppStateContext();
  const { totalQualityScore } = getQualityScore(state.preflightTest.report, state.bitrateTest.report);
  const classes = useStyles();

  const testsPassed = totalQualityScore === QualityScore.Excellent || totalQualityScore === QualityScore.Good;
  const qualityScore = QualityScore[totalQualityScore].toLowerCase();

  return (
    <>
      <Container>
        <div>
          <Grid item lg={5} className={classes.header}>
            <Typography variant="h1" gutterBottom>
              {testsPassed ? t('Result.Passed.Title') : t('Result.Failed.Title')}
            </Typography>

            {testsPassed ? (
              <Typography variant="body1" gutterBottom>
                {t('Result.Passed.Desc')}
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                <strong>{t('Result.Failed.Desc_01')}</strong>
                {t('Result.Failed.Desc_02')}
                {t('Result.Failed.Desc_03')}
              </Typography>
            )}

            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.downloadButton}
                onClick={downloadFinalTestResults}
                style={{ color: 'white' }}
              >
                <DownloadIcon />
                {t('Result.Button.Download')}
              </Button>
              <Button variant="outlined" className={classes.restartButton} onClick={() => window.location.reload()}>
                {t('Result.Button.Restart')}
              </Button>
            </div>

            <div>
              <img
                src={testsPassed ? TestsPassed : SomeFailed}
                alt={testsPassed ? 'Success' : 'Some Failed'}
                className={classes.illustration}
              />
            </div>
          </Grid>

          <Grid item lg={5} className={classes.resultsList}>
            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  {t('Result.DeviceNetworkSetup')}
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                {t('Result.DeviceNetworkSetup.Desc')}
              </Typography>

              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.CameraTest })}
                >
                  {t('Result.DeviceNetworkSetup.Button.Hardware')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.BrowserTest })}
                >
                  {t('Result.DeviceNetworkSetup.Button.Browser')}
                </Button>
              </div>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                <CheckMark />
                <Typography variant="h3" className={classes.gutterBottom}>
                  {t('Head.Connectivity')}
                </Typography>
              </div>
              <Typography variant="body1" className={classes.gutterBottom}>
                {t('Result.Connectivity.Desc')}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.Connectivity })}
              >
                {t('Result.DeviceNetworkSetup.Button.Connectivity')}
              </Button>
            </div>

            <div className={classes.resultContainer}>
              <div className={classes.iconContainer}>
                {testsPassed ? <CheckMark /> : <SmallError />}
                <Typography variant="h3" className={classes.gutterBottom}>
                  {t('Head.QualityPerformance')}
                </Typography>
              </div>

              {testsPassed ? (
                <Typography variant="body1" className={classes.gutterBottom}>
                  {t('Result.Passed.QualityPerformance_01')}
                  <strong>{t(qualityScore)}</strong>
                  {t('Result.Passed.QualityPerformance_01')}
                  {qualityScore === 'excellent' ? ` ${t('Good')}` : ` ${t('Button.ok')}`}.
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.gutterBottom}>
                  {t('Result.Failed.QualityPerformance_01')}
                  <strong>{t(qualityScore)}</strong>
                  {t('Result.Failed.QualityPerformance_02')}
                </Typography>
              )}

              <Button
                variant="outlined"
                onClick={() => dispatch({ type: 'set-active-pane', newActivePane: ActivePane.Quality })}
              >
                {t('Result.Button.ReviewPerformance')}
              </Button>
            </div>
          </Grid>
        </div>
      </Container>
    </>
  );
}
